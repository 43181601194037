import { useState } from "react";
import "./App.css";
import actors from "./actors";

const getRandomActorIndex = () => {
  const randomActorIndex = Math.floor(Math.random() * actors.length);
  return randomActorIndex;
};

function App() {
  const [actorCombos, updateActorCombos] = useState([]);
  const [actorCache, useActorCache] = useState("");
  const [actorCombo, setActorCombo] = useState([
    actors[getRandomActorIndex()],
    actors[getRandomActorIndex()],
  ]);

  const getNewActorCombo = () => {
    setActorCombo([
      actors[getRandomActorIndex()],
      actors[getRandomActorIndex()],
    ]);
  };

  return (
    <div className="App">
      <div className="flex flex-col w-full items-center h-screen absolute inset-0">
        <div className="flex w-full flex-1 items-center justify-center text-white text-5xl border-b-4">
          {actorCombo[0]}
        </div>
        <div className="flex w-full flex-1 items-center justify-center text-white text-5xl bg-purple-900">
          {actorCombo[1]}
        </div>
        <button
          className="flex w-full items-center justify-center text-2xl text-white py-8 cursor-pointer bg-green-900 hover:bg-green-800 border-t-4 select-none"
          onClick={getNewActorCombo}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default App;
