const actors = [
  "Kevin Bacon",
  "Chiwetel Ejiofor",
  "Hugh Grant",
  "Mahershala Ali",
  "Matthew McConaughey",
  "Woody Harrelson",
  "Natalie Portman",
  "Issa Rae",
  "Regina King",
  "Brad Pitt",
  "Angelina Jolie",
  "Amy Adams",
  "Bradley Cooper",
  "Tom Cruise",
  "Jessie Plemons",
  "Kirsten Dunst",
  "Tobey Maguire",
  "Leonardo DiCaprio",
  "Matt Damon",
  "Robert DeNiro",
  "Ben Affleck",
  "Ana de Armas",
  "Dev Patel",
  "Jessica Chastain",
  "Cate Blanchette",
  "Louis C.K.",
  "Christian Bale",
  "Ryan Gosling",
  "Jessica Lawrence",
  "Meryl Streep",
  "Christopher Walken",
  "Alicia Vikander",
  "Christoph Waltz",
  "Michael J. Fox",
  "Jamie Foxx",
  "Rami Malek",
  "Léa Seydoux",
  "Daniel Craig",
  "Paul Newman",
  "Audrey Hepburn",
  "Albert Finney",
  "Peter Sellers",
  "George C. Scott",
  "Marlene Dietrich",
  "Grace Kelly",
  "Sophia Loren",
  "Meg Ryan",
  "Lauren Bacall",
  "Greta Garbo",
  "Lena Dunham",
  "Timothée Chalamet",
  "Zendaya",
  "Jeremy Renner",
  "Osar Isaac",
  "Isla Fisher",
  "Owen Wilson",
  "Denzel Washington",
  "Robert Pattinson",
  "LaKeith Stanfield",
  "Vince Vaughan",
  "John C. Reilly",
  "Will Ferrell",
  "Denise Richards",
  "Eddie Murphy",
  "Daniel Day-Lewis",
  "Paul Dano",
  "Nicole Kidman",
  "Idris Elba",
  "Frances McDormand",
  "Charlize Theron",
  "Julianne Moore",
  "Kate Winslet",
  "Sandra Bullock",
  "Scarlett Johansson",
  "Viola Davis",
  "Halle Berry",
  "Jane Fonda",
  "Reese Witherspoon",
  "Glenn Close",
  "Emma Stone",
  "Tom Hanks",
  "Sally Field",
  "Diane Keaton",
  "Lawrence Fishburne",
  "Keanu Reeves",
  "Tom Holland",
  "Benedict Cumberbatch",
  "Simu Liu",
  "Awkwafina",
];

export default actors;
